








































































































import Vue, { PropType } from 'vue';
import potokLogoImg from '../../assets/potok-logo.svg';

type Route = {
  text: string
  href: string;
  to?: string;
  event?: unknown;
  testLabel?: string;
  testValue?: string;
}

export default Vue.extend({
  name: 'AppNavbar',
  props: {
    currentApp: {
      type: String,
      default: '/',
    },
    items: {
      type: Array as PropType<Route[]>,
      default: () => [] as Route[],
    },
    brandUrl: {
      type: String,
      default: null,
    },
    clippedRight: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: '',
    },
    brandName: {
      type: String,
      default: '',
    },
    logoUrl: {
      type: [String, Object],
      default: null,
    },
    companyLogoImgUrl: {
      type: String,
      default: null as String | null,
    },
  },
  data() {
    return {
      logoImg: null as String | null,
    };
  },
  computed: {
    isLabelVisible() {
      return Boolean(this.labelText || this.$scopedSlots.label);
    },
    isExternalLogoUrl() {
      if (typeof this.logoUrl === 'string') {
        return /^(https?:)?\/\//i.test(this.logoUrl);
      }
      return false;
    },
  },
  watch: {
    companyLogoImgUrl: {
      handler(val) {
        if (val) {
          this.logoImg = val;
        }
      },
      immediate: true,
    },
  },
  methods: {
    isExternalLink(route: Route) {
      return (!route.event && route.href) || undefined;
    },
    onLogoImgError() {
      this.logoImg = potokLogoImg;
    },
  },
});
